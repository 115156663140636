var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-creation-wrapper"},[_c('div',{staticClass:"wrapper-header"},[_c('VPageHeader',{staticClass:"header"},[_c('div',[_c('VBreadcrumbs',{attrs:{"current-route":_vm.currentRoute,"routes":_vm.routes}}),_c('div',{staticClass:"wrapper-title"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',[_c('PMChartStatus',{attrs:{"status":_vm.status,"statuses":_vm.statuses}})],1)])],1)]),_c('div',{staticClass:"action"},[_c('VButton',{attrs:{"disabled":!_vm.approvalEnabled},on:{"click":_vm.actionHandler}},[_vm._v(_vm._s(_vm.actionTitle))]),_c('div',{staticClass:"wrapper-icon"},[_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('history'),
                        placement: 'left',
                        classes: 'tooltip--without-arrow',
                        defaultHideOnTargetClick: false,
                        autoHide: false,
                    }),expression:"{\n                        content: $t('history'),\n                        placement: 'left',\n                        classes: 'tooltip--without-arrow',\n                        defaultHideOnTargetClick: false,\n                        autoHide: false,\n                    }"}],attrs:{"color":"green"},on:{"click":_vm.showHistory}},[_c('HistorySVG')],1)],1)],1)],1),_vm._t("default"),_c('PMChartHistory',{ref:"chartHistory"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }